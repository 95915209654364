import { RapportinoDTO } from 'src/app/shared/dto/rapportino/rapportino';
import { AttivitaService } from './../services/rapportino/attivita.service';
import { ClienteDTO } from './../shared/dto/cliente/cliente';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { RapportinoService } from './../services/rapportino/rapportino.service';
import { PresenzaDTO } from './../shared/dto/presenze/presenza';
import { PresenzaService } from './../services/presenze/presenza.service';
import { ResponseQueryByCriteria } from './../shared/dto/responseQueryByCriteria';
import { ResponseFail } from './../shared/dto/responseFail';
import { HttpErrorResponse } from '@angular/common/http';
import { DateAdapter } from '@angular/material/core';
import { TimestampFormatPipe } from './../commons/timestampFormatPipe';
import { CollaboratoreService } from './../services/domain/collaboratore.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RapportinoFinaleService } from './../services/rapportino/rapportino-finale.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigatorService } from './../services/navigator.service';
import { RapportinoFinaleDTO } from './../shared/dto/rapportino/rapportinoFinale';
import { GenericDetailComponent } from './../shared/GenericDetailComponent';
import { AfterContentChecked, ChangeDetectorRef, Component, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AttivitaDTO } from '../shared/dto/rapportino/attivita';
import { ClienteService } from '../services/cliente/cliente.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AssenzaDTO } from '../shared/dto/assenza/assenza';
import { AssenzaService } from '../services/assenza/assenza.service';
import { AssenzaRapportinoDTO } from '../shared/dto/rapportino/assenzaRapportino';
import { StampaTabellaRapportinoDTO } from '../shared/dto/rapportino/stampaTabellaRapportino';
import { PresenzaRapportini } from '../shared/dto/rapportino/presenzaRapportini';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4plugins from "@amcharts/amcharts4/plugins/sunburst";
import { isPlatformBrowser } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PieValuesDTO } from '../shared/dto/pie_values/pieValues';
import { BreakpointObserver } from '@angular/cdk/layout';
import {Color, isString} from '@amcharts/amcharts4/core';
import { MalattiaService } from '../services/malattia/malattia.service';
import { MalattiaDTO } from '../shared/dto/malattia/malattia';
import { MalattiaRapportinoDTO } from '../shared/dto/rapportino/malattiaRapportino';
import {from} from 'rxjs';
import {groupBy, mergeMap, reduce, skip, toArray} from 'rxjs/operators';
import {getWeekOfMonth} from 'date-fns';



@Component({
  selector: 'app-rapportino-finale-detail',
  templateUrl: './rapportino-finale-detail.component.html',
  styleUrls: ['./rapportino-finale-detail.component.scss']
})
export class RapportinoFinaleDetailComponent extends GenericDetailComponent implements OnInit, AfterContentChecked {
  //grafico
  private chartAttivita: am4charts.XYChart;
  private inData = [];

  //rapportino
  statoNuovoRapportino: boolean;
  formRapportini: FormGroup;
  rapportinoFinaleId: number;
  rapportinoFinale: RapportinoFinaleDTO;
  rapportino: RapportinoDTO;
  ultimoRapportino: RapportinoDTO;
  collaboratori: CollaboratoreDTO[];
  rapportini: RapportinoDTO[];
  attivita: AttivitaDTO[];
  attivitaSingola: AttivitaDTO;
  clienti: ClienteDTO[];
  assenze: AssenzaDTO[];
  presenzaPrecedente: PresenzaDTO;
  statoRapportinoFinale: selectedValue[];
  feriePermessi: AssenzaRapportinoDTO[];
  permessiMenoOttoOre: RapportinoDTO[];
  presenzaRapportini: PresenzaRapportini[];
  vistaTabellare: StampaTabellaRapportinoDTO[];
  filtro: string;
  parameters;
  parametersRapportini;
  displayedColumns: string[];
  columnsToDisplay: string[];
  defaultSortField = 'dataPresenza';
  defaultSortFieldRapportino = 'presenza.dataPresenza';
  defaultSortFieldStampaRapportino = 'data';
  defaultSortDirection = 'desc';
  defaultSortDirectionRapportino = 'asc';
  lettura: boolean = false;
  locale: string = "it";
  tabellaRaggruppata = [];
  tabellaPerSettimana = [];
  //AutoComplete
  filteredAttivita: AttivitaDTO[];
  private descrizioneTimer: any;
  filteredCliente: ClienteDTO[];
  private descrizioneTimerCliente: any;

  mesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
  giorni = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  constructor(
    @Inject(PLATFORM_ID)
    private platformId,
    private zone: NgZone,
    private breakpointObserver: BreakpointObserver,
    //grafico^^
    navigatorService: NavigatorService,
    private presenzaService: PresenzaService,
    private rapportinoService: RapportinoService,
    private clienteService: ClienteService,
    private attivitaService: AttivitaService,
    private assenzaService: AssenzaService,
    private malattiaService: MalattiaService,
    activatedRoute: ActivatedRoute,
    private rapportinoFinaleService: RapportinoFinaleService,
    snackBar: MatSnackBar,
    dialog: MatDialog,
    private collaboratoreService: CollaboratoreService,
    router: Router,
    timestampFormatPipe: TimestampFormatPipe,
    dateAdapter: DateAdapter<Date>,
    private cdr: ChangeDetectorRef) {
    super(
      navigatorService,
      dialog,
      router,
      dateAdapter,
      activatedRoute,
      snackBar,
      timestampFormatPipe
    );
    this.form = new FormGroup({
      id: new FormControl({ value: '', disabled: true }),
      collaboratore: new FormControl(null, Validators.required),
      anno: new FormControl(null, Validators.required),
      mese: new FormControl(null, Validators.required),
      stato: new FormControl(null, Validators.required),
      deleteDate: new FormControl({ value: '', disabled: true }),
      deleteUser: new FormControl({ value: '', disabled: true }),
      insertDate: new FormControl({ value: '', disabled: true }),
      insertUser: new FormControl({ value: '', disabled: true }),
      updateDate: new FormControl({ value: '', disabled: true }),
      updateUser: new FormControl({ value: '', disabled: true }),
      optLock: new FormControl({ value: '', disabled: true }),
    });
    this.formRapportini = new FormGroup({
      cliente: new FormControl('', Validators.required),
      attivitaControl: new FormControl('', Validators.required),
      nOre: new FormControl('', [Validators.required, Validators.min(1), Validators.max(24)]),
      clienteFinale: new FormControl('', Validators.required)
    });
    this.statoRapportinoFinale = [
      { value: 'WORKER', viewValue: 'IN LAVORAZIONE' },
      { value: 'DONE', viewValue: 'IN APPROVAZIONE' },
      { value: 'CLOSED', viewValue: 'CHIUSO' }
    ];
    this.parameters = {
      dataSource: [],
      collaboratore: null,
      dataDa: Date,
      dataA: Date,
      sortField: this.defaultSortField,
      sortDirection: this.defaultSortDirection,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
    this.displayedColumns = ['collaboratore', 'data', 'numeroOre', 'cliente', 'location', 'attivita', 'clienteFinale'];
    this.columnsToDisplay = ['cliente', 'numeroOre', 'attivita', 'clienteFinale', 'delete'];
    this.parametersRapportini = {
      dataSource: [],
      sortField: this.defaultSortFieldRapportino,
      sortDirection: this.defaultSortDirectionRapportino,
      pageNumber: 0,
      pageSize: 50,
      length: 0
    }
    this.statoNuovoRapportino = false;
    this.permessiMenoOttoOre = [];
    this.feriePermessi = [];
    this.presenzaRapportini = [];
    this.vistaTabellare = [];
    this.presenzaPrecedente = new PresenzaDTO();
  }

  ngOnInit() {
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.parameters.collaboratore = res;
      }
    );
    this.rapportinoFinaleId = Number(this.activeRoute.snapshot.paramMap.get("id"));
    if (this.rapportinoFinaleId != null && this.rapportinoFinaleId != 0) {
      this.rapportinoFinaleRead();
    } else {
      this.rapportinoFinaleInit();
    }
    this.rapportinoService.rapportinoList(
      0,
      9999,
      'ASC',
      'presenza.dataPresenza',
      this.rapportinoFinaleId
    ).subscribe(
      (res: ResponseQueryByCriteria<RapportinoDTO>) => {
        this.rapportini = res.content;
        this.inData = res.content;
      }
    );
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          this.collaboratori = res.content;
        }
      );
    this.clienteService.clienteList(
      0,
      9999,
      'ASC',
      'descrizione',
      ''
    ).subscribe(
      (res: ResponseQueryByCriteria<ClienteDTO>) => {
        this.clienti = res.content;
      }
    );
  }

  inizializzaDescrizione = () => (value: AttivitaDTO | string): string => {
    if (typeof value == 'string') {
      return value;
    }
    return value.descrizione;
  }

  inizializzaDescrizioneCliente = () => (value: ClienteDTO | string): string => {
    if (typeof value == 'string') {
      return value;
    }
    return value.descrizione;
  }

  clienteDescrizioneChanged(newValue: string, fromUser: boolean) {
    if (fromUser) {
      clearTimeout(this.descrizioneTimerCliente);
      this.descrizioneTimer = setTimeout(() => {
        this.clienteDescrizioneChanged(newValue, false);
      }, 250);
    } else {
      this.clienteService.clienteList(
        0,
        9999,
        'ASC',
        'descrizione',
        newValue
      ).subscribe(
        (res: ResponseQueryByCriteria<ClienteDTO>) => {
          this.filteredCliente = res.content;
        });
    }
    this.cdr.markForCheck();
  }

  descrizioneChanged(newValue: string | AttivitaDTO, fromUser: boolean) {
    if (fromUser) {
      clearTimeout(this.descrizioneTimer);
      this.descrizioneTimer = setTimeout(() => {
        this.descrizioneChanged(newValue, false);
      }, 250);
    } else {
      if (typeof newValue == "string") {
        this.filtro = newValue;
      } else this.filtro = newValue.descrizione;
      this.attivitaService.attivitaList(
        0,
        9999,
        'ASC',
        'descrizione',
        this.rapportinoFinale.collaboratore,
        this.filtro
      ).subscribe(
        (res: ResponseQueryByCriteria<AttivitaDTO>) => {
          this.filteredAttivita = res.content;

        });
    }

  }

  ultimoRapp() {
    this.rapportinoService.ultimoRapportino(this.rapportinoFinale.collaboratore).subscribe(
      (res: RapportinoDTO) => {
        this.ultimoRapportino = res;
        if (this.ultimoRapportino != null) {
          this.formRapportini.get("cliente").setValue(this.ultimoRapportino.cliente)
          this.formRapportini.get("nOre").setValue(this.ultimoRapportino.numeroOre)
          this.formRapportini.get("attivitaControl").setValue(this.ultimoRapportino.attivita)
          this.formRapportini.get("clienteFinale").setValue(this.ultimoRapportino.clienteFinale)

        }
      }
    );
  }

  nuovoRapportino(presenzaEP: MatExpansionPanel) {
    this.ultimoRapp();
    this.statoNuovoRapportino = true;
    presenzaEP.close();
    this.formRapportini.markAsDirty();
  }



  negaStatoRapportinoNuovo(presenzaEP: MatExpansionPanel) {
    this.statoNuovoRapportino = false;
    presenzaEP.open();
  }

  chiudi(assenza: MatExpansionPanel, object: any) {
    if (object instanceof AssenzaRapportinoDTO) {
      assenza.close();
    }
  }

  rapportiniPresenza(presenza: PresenzaDTO): Array<RapportinoDTO> {
    let array: RapportinoDTO[] = [];
    for (let i = 0; i < this.rapportini.length; i++) {
      if (this.rapportini[i].presenza.id == presenza.id) {
        array.push(this.rapportini[i]);
      }
    }

    if (this.presenzaPrecedente.dataPresenza != presenza.dataPresenza) {
      if (this.assenze != null) {
        this.assenze.forEach(assenza => {
          if (assenza.ore < 8 && assenza.tipoRichiesta == "PERMESSO" && assenza.dataDa == presenza.dataPresenza) {
            let assenzaRapportino = new RapportinoDTO();
            let cliente = new ClienteDTO();
            let attivita = new AttivitaDTO();
            cliente.descrizione = "-";
            attivita.descrizione = "PERMESSO";
            assenzaRapportino.collaboratore = assenza.richiedente;
            assenzaRapportino.presenza = presenza;
            assenzaRapportino.cliente = cliente;
            assenzaRapportino.attivita = attivita;
            assenzaRapportino.numeroOre = assenza.ore;
            array.push(assenzaRapportino);
            this.permessiMenoOttoOre.push(assenzaRapportino);
          }
        })
      }
    }
    this.presenzaPrecedente = presenza;
    return array;
  }

  rapportinoFinaleSave() {
    if (this.rapportinoFinale.id == null) {
      this.rapportinoFinaleCreate();
    } else {
      this.rapportinoFinaleUpdate();
    }
  }

  rapportinoFinaleCreate() {
    if (this.form.valid) {
      this.formToDto();
      this.rapportinoFinaleService.create(this.rapportinoFinale).subscribe(
        (res: RapportinoFinaleDTO) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.rapportinoFinale = res;
          this.rapportinoFinaleId = this.rapportinoFinale.id;
          this.rapportinoFinaleRead();
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidRapportinoFinaleException_RAPPORTINOFINALE_NOT_CREABLE") {
            this.snackBar.open("Attenzione! Rapportino Mensile già esistente o nessuna presenza inserita per tale mese", null, { duration: 4000 });
          }
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidRapportinoFinaleException_RAPPORTINOFINALE_NOT_CREABLE_DATE") {
            this.snackBar.open("Attenzione! Rapportino Mensile creabile solo per il mese corrente o per il mese precedente", null, { duration: 4000 });
          }
        });
    }
  }

  attivitaCreate(descrizione: string, presenza: PresenzaDTO) {
    if (this.form.valid) {
      if (this.attivitaSingola == null) {
        this.attivitaSingola = new AttivitaDTO();
      }
      this.ToDtoAttivita(descrizione);
      this.attivitaService.create(this.attivitaSingola).subscribe(
        (res: AttivitaDTO) => {
          this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
          this.attivitaSingola = res;
          this.formToDtoRapportinoWithAttivita(presenza, this.attivitaSingola);
          this.rapportinoService.create(this.rapportino).subscribe(
            (res: RapportinoDTO) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              this.rapportino = res;
              this.statoNuovoRapportino = false;
              this.rapportinoService.rapportinoList(
                0,
                9999,
                'ASC',
                'presenza.dataPresenza',
                this.rapportinoFinaleId
              ).subscribe(
                (res: ResponseQueryByCriteria<RapportinoDTO>) => {
                  this.rapportini = res.content;
                  this.permessiMenoOttoOre = [];
                  this.presenzaPrecedente = new PresenzaDTO();
                  for (let i = 0; i < this.presenzaRapportini.length; i++) {
                    if (!(this.presenzaRapportini[i].presenza instanceof AssenzaRapportinoDTO)) {
                      let somma = 0;
                      this.presenzaRapportini[i].rapportini = this.rapportiniPresenza(this.presenzaRapportini[i].presenza as PresenzaDTO);
                      for (let j = 0; j < this.presenzaRapportini[i].rapportini.length; j++) {
                        if (this.presenzaRapportini[i].rapportini[j].attivita.descrizione != "PERMESSO") {
                          somma += this.presenzaRapportini[i].rapportini[j].numeroOre;
                        }
                      }
                      this.presenzaRapportini[i].presenza.oreCensite = somma;
                    }
                  }
                  this.inData = res.content;
                  this.stampaTabella();
                }
              );
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidRapportinoException_RAPPORTINO_ALREADY_EXISTS") {
                this.snackBar.open("Attenzione! Rapportino già esistente", null, { duration: 4000 });
              }
              if (err.status == 500) {
                this.snackBar.open("Qualcosa è andato storto! Ricontrollare i campi inseriti", null, { duration: 4000 });
              }
            });
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidAttivitaException_ATTIVITA_DESCRIZIONE_EXIST") {
            this.snackBar.open("Attenzione! Attivita già esistente", null, { duration: 4000 });
          }
        });
    }
  }

  rapportinoCreate(presenza: PresenzaDTO) {
    if (this.formRapportini.valid) {
      let cliente: string;
      if (typeof this.formRapportini.get("cliente").value == 'string') {
        cliente = this.formRapportini.get("cliente").value;
      } else {
        cliente = this.formRapportini.get("cliente").value.descrizione;
      }
      this.clienteService.clienteByDescrizione(
        cliente
      ).subscribe(
        (res: ClienteDTO) => {
          this.attivitaService.attivitByDescrizione(this.rapportinoFinale.collaboratore, this.filtro).subscribe(
            (res: AttivitaDTO) => {
              if (res == null && typeof this.formRapportini.get("attivitaControl").value == "string") {
                this.attivitaCreate(this.filtro, presenza);
              } else {
                let attivitaEstratta: AttivitaDTO;
                if (typeof this.formRapportini.get("attivitaControl").value == 'string') {
                  attivitaEstratta = res;
                } else {
                  attivitaEstratta = this.formRapportini.get("attivitaControl").value;
                }
                this.formToDtoRapportino(presenza, attivitaEstratta);
                this.rapportinoService.create(this.rapportino).subscribe(
                  (res: RapportinoDTO) => {
                    this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
                    this.rapportino = res;
                    this.statoNuovoRapportino = false;
                    this.rapportinoService.rapportinoList(
                      0,
                      9999,
                      'ASC',
                      'presenza.dataPresenza',
                      this.rapportinoFinaleId
                    ).subscribe(
                      (res: ResponseQueryByCriteria<RapportinoDTO>) => {
                        this.rapportini = res.content;
                        this.permessiMenoOttoOre = [];
                        this.presenzaPrecedente = new PresenzaDTO();
                        for (let i = 0; i < this.presenzaRapportini.length; i++) {
                          if (!(this.presenzaRapportini[i].presenza instanceof AssenzaRapportinoDTO)) {
                            let somma = 0;
                            this.presenzaRapportini[i].rapportini = this.rapportiniPresenza(this.presenzaRapportini[i].presenza as PresenzaDTO);
                            for (let j = 0; j < this.presenzaRapportini[i].rapportini.length; j++) {
                              if (this.presenzaRapportini[i].rapportini[j].attivita.descrizione != "PERMESSO") {
                                somma += this.presenzaRapportini[i].rapportini[j].numeroOre;
                              }
                            }
                            this.presenzaRapportini[i].presenza.oreCensite = somma;
                          }
                        }
                        this.inData = res.content;
                        this.stampaTabella();
                      }
                    );
                  }, (err: HttpErrorResponse) => {
                    if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidRapportinoException_RAPPORTINO_ALREADY_EXISTS") {
                      this.snackBar.open("Attenzione! Attività già inserita per il cliente e il giorno selezionat", null, { duration: 4000 });
                    }
                  });
              }
            }
          )
        }, (err: HttpErrorResponse) => {
          if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidClienteException_CLIENTE_NOT_FOUND") {
            this.snackBar.open("Attenzione! Cliente non trovato", null, { duration: 4000 });
          }
        })
    }
  }


  rapportinoFinaleUpdate() {
    if (this.form.valid && this.form.dirty) {
      this.confirm("Sei sicuro di voler sovrascrivere il Rapportino Mensile?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.rapportinoFinaleService.update(this.rapportinoFinale).subscribe(
            (res) => {
              this.snackBar.open("Salvataggio avvenuto con successo!", null, { duration: 3000 });
              if (this.rapportinoFinale.statoRapportinoFinale == "DONE" || this.rapportinoFinale.statoRapportinoFinale == "CLOSED") {
                this.statoNuovoRapportino = false;
              }
              this.rapportinoFinaleRead();
              if (!this.isJappTenantAdmin() && (this.rapportinoFinale.statoRapportinoFinale == 'CLOSED' || this.rapportinoFinale.statoRapportinoFinale == 'DONE')) {
                this.form.get("stato").disable();
              } else this.form.get("stato").enable();
              this.form.markAsPristine();
            }, (err: HttpErrorResponse) => {
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidRapportinoFinaleException_RAPPORTINOFINALE_NOT_CREABLE") {
                this.snackBar.open("Attenzione! Rapportino Mensile già esistente o nessuna presenza inserita per tale mese", null, { duration: 4000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidRapportinoFinaleException_RAPPORTINOFINALE_NOT_CREABLE_DATE") {
                this.snackBar.open("Attenzione! Rapportino Mensile modificabile solo per il mese corrente o per il mese precedente", null, { duration: 4000 });
              }
              if (err.status == 400 && (<ResponseFail>err.error).reasonBundleKey == "InvalidRapportinoFinaleException_RAPPORTINOFINALE_UNEDITABLE") {
                this.snackBar.open("Attenzione! Impossibile modificare lo stato del rapportino a CHIUSO se non si hanno i permessi", null, { duration: 4000 });
                this.rapportinoFinaleRead();
              }
            });
        }
      });
    }
  }

  rapportinoFinaleDelete() {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il Rapportino Mensile?").subscribe(result => {
        if (result) {
          this.formToDto();
          this.rapportinoFinaleService.delete(this.rapportinoFinale.id).subscribe(
            (res) => {
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.router.navigate(["/rapportino-finale"]);
            }
          );
        }
      });
    }
  }

  rapportinoDelete(rapportino: RapportinoDTO, presenzaEP: MatExpansionPanel) {
    if (this.form.valid) {
      this.confirm("Sei sicuro di voler cancellare il Rapportino Mensile?").subscribe(result => {
        if (result) {
          this.rapportinoService.delete(rapportino.id).subscribe(
            (res) => {
              this.snackBar.open("Cancellazione avvenuta con successo!", null, { duration: 3000 });
              this.rapportinoService.rapportinoList(
                0,
                9999,
                'ASC',
                'presenza.dataPresenza',
                this.rapportinoFinaleId
              ).subscribe(
                (res: ResponseQueryByCriteria<RapportinoDTO>) => {
                  this.rapportini = res.content;
                  this.permessiMenoOttoOre = [];
                  this.presenzaPrecedente = new PresenzaDTO();
                  for (let i = 0; i < this.presenzaRapportini.length; i++) {
                    if (!(this.presenzaRapportini[i].presenza instanceof AssenzaRapportinoDTO)) {
                      let somma = 0;
                      this.presenzaRapportini[i].rapportini = this.rapportiniPresenza(this.presenzaRapportini[i].presenza as PresenzaDTO);
                      for (let j = 0; j < this.presenzaRapportini[i].rapportini.length; j++) {
                        if (this.presenzaRapportini[i].rapportini[j].attivita.descrizione != "PERMESSO") {
                          somma += this.presenzaRapportini[i].rapportini[j].numeroOre;
                        }
                      }
                      this.presenzaRapportini[i].presenza.oreCensite = somma;
                    }
                  }
                  this.inData = res.content;
                  this.stampaTabella();
                }
              );
            }
          );
        }
      });
    }
  }

  rapportinoFinaleRead() {
    this.lettura = true;

    this.rapportinoFinaleService.read(this.rapportinoFinaleId).subscribe(
      (res: RapportinoFinaleDTO) => {
        this.rapportinoFinale = res;
        this.rapportinoFinaleId = res.id;
        this.parameters.dataDa = new Date(res.data);
        this.parameters.dataA = new Date(this.rapportinoFinale.anno, this.rapportinoFinale.mese, 0);
        this.dtoToForm();
        this.form.get("collaboratore").disable();
        this.form.get("mese").disable();
        this.form.get("anno").disable();
        if (!this.isJappTenantAdmin() && (this.rapportinoFinale.statoRapportinoFinale == 'CLOSED' || this.rapportinoFinale.statoRapportinoFinale == 'DONE')) {
          this.form.get("stato").disable();
        } else this.form.get("stato").enable();
        this.listPresenze();
        this.listAttivita();
      }
    );
  }

  rapportinoFinaleInit() {
    this.lettura = false;
    this.rapportinoFinale = new RapportinoFinaleDTO();
    this.navigatorService.collaboratore.subscribe(
      (res: CollaboratoreDTO) => {
        this.form.get("collaboratore").setValue(res);
        this.form.get("mese").setValue(this.mesi[new Date().getMonth()])
        this.form.get("anno").setValue(new Date().getFullYear())
        this.form.get("stato").setValue("WORKER");
        this.form.get("collaboratore").enable();
        this.form.get("mese").enable();
        this.form.get("anno").enable();
        this.form.get("stato").disable();
      }
    );
  }

  listAttivita() {
    this.attivitaService.attivitaList(
      0,
      9999,
      'ASC',
      'descrizione',
      this.rapportinoFinale.collaboratore,
      ''
    ).subscribe(
      (res: ResponseQueryByCriteria<AttivitaDTO>) => {
        this.attivita = res.content;
      }
    );
  }

  listAssenze() {
    this.assenzaService.assenzaListByList(
      0,
      9999,
      'ASC',
      'dataDa',
      this.rapportinoFinale.collaboratore,
      'APPROVATO',
      this.parameters.dataDa,
      this.parameters.dataA,
      null
    ).subscribe(
      (res: ResponseQueryByCriteria<AssenzaDTO>) => {
        this.assenze = res.content;
        this.presenzaRapportini = [];
        for (let i = 0; i < this.parameters.dataSource.length; i++) {
          let somma = 0;
          let presenzaRapportino = new PresenzaRapportini();
          let rapportini = this.rapportiniPresenza(this.parameters.dataSource[i]);
          for (let j = 0; j < rapportini.length; j++) {
            presenzaRapportino.rapportini.push(rapportini[j]);
            if (rapportini[j].attivita.descrizione != "PERMESSO") {
              somma += rapportini[j].numeroOre;
            }
          }
          this.parameters.dataSource[i].oreCensite = somma;
          presenzaRapportino.presenza = this.parameters.dataSource[i];
          this.presenzaRapportini.push(presenzaRapportino);
        }
        this.feriePermessi = [];
        this.assenze.forEach(assenza => {
          if (assenza.tipoRichiesta == "FERIE") {
            if (assenza.dataDa == assenza.dataA) {
              var d = new Date(assenza.dataDa);
              if (new Date(assenza.dataDa).getDay() != this.giorni.indexOf("Sunday") && new Date(assenza.dataDa).getDay() != this.giorni.indexOf("Saturday") && d <= new Date(this.rapportinoFinale.anno, this.rapportinoFinale.mese, 0) && d >= new Date(this.rapportinoFinale.data)) {
                let assenzaRapportino = new AssenzaRapportinoDTO();
                let presenzaRapportini = new PresenzaRapportini();
                assenzaRapportino.collaboratore = assenza.richiedente;
                assenzaRapportino.dataPresenza = assenza.dataDa;
                assenzaRapportino.tipo = assenza.tipoRichiesta;
                assenzaRapportino.numeroDiOre = 8;
                assenzaRapportino.oreCensite = 8;
                presenzaRapportini.presenza = assenzaRapportino;
                this.presenzaRapportini.push(presenzaRapportini);
                this.feriePermessi.push(assenzaRapportino);
              }
            } else {
              for (var d = new Date(assenza.dataDa); d <= new Date(assenza.dataA); d.setDate(d.getDate() + 1)) {
                if (d.getDay() != this.giorni.indexOf("Sunday") && d.getDay() != this.giorni.indexOf("Saturday") && d <= new Date(this.rapportinoFinale.anno, this.rapportinoFinale.mese, 0) && d >= new Date(this.rapportinoFinale.data)) {
                  let assenzaRapportino = new AssenzaRapportinoDTO();
                  let presenzaRapportini = new PresenzaRapportini();
                  assenzaRapportino.collaboratore = assenza.richiedente;
                  assenzaRapportino.dataPresenza = new Date(d);
                  assenzaRapportino.tipo = assenza.tipoRichiesta;
                  assenzaRapportino.numeroDiOre = 8;
                  assenzaRapportino.oreCensite = 8;
                  presenzaRapportini.presenza = assenzaRapportino;
                  this.presenzaRapportini.push(presenzaRapportini);
                  this.feriePermessi.push(assenzaRapportino);
                }
              }
            }
          } else if (assenza.tipoRichiesta == "PERMESSO") {
            if (assenza.ore >= 8) {
              if (assenza.ore == 8) {
                let assenzaRapportino = new AssenzaRapportinoDTO();
                let presenzaRapportini = new PresenzaRapportini();
                assenzaRapportino.collaboratore = assenza.richiedente;
                assenzaRapportino.dataPresenza = assenza.dataDa;
                assenzaRapportino.tipo = assenza.tipoRichiesta;
                assenzaRapportino.numeroDiOre = 8;
                assenzaRapportino.oreCensite = 8;
                presenzaRapportini.presenza = assenzaRapportino;
                this.presenzaRapportini.push(presenzaRapportini);
                this.feriePermessi.push(assenzaRapportino);
              } else {
                for (var d = new Date(assenza.dataDa); d <= new Date(assenza.dataA); d.setDate(d.getDate() + 1)) {
                  if (d.getDay() != this.giorni.indexOf("Sunday") && d.getDay() != this.giorni.indexOf("Saturday") && d <= new Date(this.rapportinoFinale.anno, this.rapportinoFinale.mese, 0) && d >= new Date(this.rapportinoFinale.data)) {
                    let assenzaRapportino = new AssenzaRapportinoDTO();
                    let presenzaRapportini = new PresenzaRapportini();
                    assenzaRapportino.collaboratore = assenza.richiedente;
                    assenzaRapportino.dataPresenza = new Date(d);
                    assenzaRapportino.tipo = assenza.tipoRichiesta;
                    assenzaRapportino.numeroDiOre = 8;
                    assenzaRapportino.oreCensite = 8;
                    presenzaRapportini.presenza = assenzaRapportino;
                    this.presenzaRapportini.push(presenzaRapportini);
                    this.feriePermessi.push(assenzaRapportino);
                  }
                }
              }
            }
          }
        })
        this.listMalattia();
      }
    );
  }

  listMalattia() {
    this.malattiaService.malattiaListByList(
      this.rapportinoFinale.collaboratore,
      this.parameters.dataDa,
      this.parameters.dataA,
      null,
      null,
      null,
      "ASC",
      "dataDa",
    ).subscribe(
      (res: ResponseQueryByCriteria<MalattiaDTO>) => {
        let tmp: MalattiaDTO[] = res.content;
          tmp.forEach(malattia => {
          let obj = new MalattiaRapportinoDTO();
          obj.collaboratore = malattia.collaboratore;
          obj.dataPresenza = malattia.dataDa;
          obj.dataA = malattia.dataA;
          obj.tipo = "Malattia";
          obj.oreCensite = 8;
          obj.numeroDiOre = 8;
          let nuovaMalattia = new PresenzaRapportini();
          nuovaMalattia.presenza = obj;
          this.presenzaRapportini.push(nuovaMalattia);
        });
        this.presenzaRapportini.sort((a, b) => {
          return new Date(a.presenza.dataPresenza).getTime() - new Date(b.presenza.dataPresenza).getTime();
        });
        this.presenzaRapportini.reverse();
      }
    )
    this.stampaTabella();
  }


  

  getAttivitaDescrizione(rapportino: RapportinoDTO) {
    if (rapportino.attivita.descrizione == "PERMESSO") {
      return true;
    };
  }

  listPresenze() {
    this.presenzaService.presenzaListByCollaboratoreAndDates(
      this.parameters.pageNumber,
      this.parameters.pageSize,
      (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
      this.parameters.sortField,
      this.rapportinoFinale.collaboratore,
      null,
      this.parameters.dataDa,
      this.parameters.dataA
    ).subscribe(
      (res: ResponseQueryByCriteria<PresenzaDTO>) => {
        this.parameters.dataSource = res.content;
        this.parameters.pageNumber = res.pageNumber;
        this.parameters.length = res.totalElements;
        this.listAssenze();
      }
    )
  }


  private formToDto(): void {
    this.rapportinoFinale.collaboratore = this.form.get("collaboratore").value;
    this.rapportinoFinale.mese = this.mesi.indexOf(this.form.get("mese").value) + 1;
    this.rapportinoFinale.anno = this.form.get("anno").value;
    this.rapportinoFinale.statoRapportinoFinale = this.form.get("stato").value;
  }

  private formToDtoRapportino(presenza: PresenzaDTO, attivita: AttivitaDTO): void {
    if (this.rapportino == null) {
      this.rapportino = new RapportinoDTO();
    }
    this.rapportino.collaboratore = this.form.get("collaboratore").value;
    this.rapportino.presenza = presenza;
    this.rapportino.cliente = this.formRapportini.get("cliente").value;
    this.rapportino.attivita = attivita;
    this.rapportino.rapportinoFinale = this.rapportinoFinale;
    this.rapportino.numeroOre = this.formRapportini.get("nOre").value;
    this.rapportino.clienteFinale = this.formRapportini.get("clienteFinale").value;
  }

  private formToDtoRapportinoWithAttivita(presenza: PresenzaDTO, attivita: AttivitaDTO): void {
    if (this.rapportino == null) {
      this.rapportino = new RapportinoDTO();
    }
    this.rapportino.collaboratore = this.form.get("collaboratore").value;
    this.rapportino.presenza = presenza;
    this.rapportino.cliente = this.formRapportini.get("cliente").value;
    this.rapportino.attivita = attivita;
    this.rapportino.rapportinoFinale = this.rapportinoFinale;
    this.rapportino.numeroOre = this.formRapportini.get("nOre").value;
    this.rapportino.clienteFinale = this.formRapportini.get("clienteFinale").value;
  }

  private ToDtoAttivita(descrizione: string): void {
    this.attivitaSingola.collaboratore = this.rapportinoFinale.collaboratore;
    this.attivitaSingola.descrizione = descrizione;
  }

  private dtoToForm(): void {
    this.form.get("id").setValue(this.rapportinoFinale.id);
    this.form.get("collaboratore").setValue(this.rapportinoFinale.collaboratore);
    this.form.get("anno").setValue(this.rapportinoFinale.anno);
    this.form.get("mese").setValue(this.mesi[this.rapportinoFinale.mese - 1]);
    this.form.get("stato").setValue(this.rapportinoFinale.statoRapportinoFinale);
    this.form.get("deleteDate").setValue(this.rapportinoFinale.deleteDate);
    this.form.get("deleteUser").setValue(this.rapportinoFinale.deleteUser);
    this.form.get("insertDate").setValue(this.timestampFormatPipe.transform(this.rapportinoFinale.insertDate));
    this.form.get("insertUser").setValue(this.rapportinoFinale.insertUser);
    this.form.get("updateDate").setValue(this.timestampFormatPipe.transform(this.rapportinoFinale.updateDate));
    this.form.get("updateUser").setValue(this.rapportinoFinale.updateUser);
    this.form.get("optLock").setValue(this.rapportinoFinale.optLock);
    this.form.markAsPristine();
  }

  controlloOre() {
    let controllo: boolean = false;
    this.parameters.dataSource.forEach(presenza => {
      if (presenza.oreCensite < presenza.numeroDiOre) {
        controllo = true;
      }
    });
    return controllo;
  }

  isValidCliente(): boolean {
    if (typeof this.formRapportini.get("cliente").value === "string") {
      this.formRapportini.get("cliente").setErrors({
        invalidCliente: true
      })
      return false;
    }
    return true;
  }

  isAssenza(object: PresenzaRapportini): boolean {
    if (object.presenza instanceof AssenzaRapportinoDTO) {
      return true;
    } else return false;
  }

  isMalattia(dato: PresenzaRapportini): boolean{
    return dato.presenza instanceof MalattiaRapportinoDTO;
  }

  stampaTabella() {
    this.vistaTabellare = [];
    this.rapportini.forEach(rapportino => {
      let elemento = new StampaTabellaRapportinoDTO();
      elemento.collaboratore = rapportino.collaboratore;
      elemento.data = new Date(rapportino.presenza.dataPresenza);
      elemento.location = rapportino.presenza.location.descrizione;
      elemento.cliente = rapportino.cliente.descrizione;
      elemento.attivita = rapportino.attivita.descrizione;
      elemento.numeroOre = rapportino.numeroOre;
      elemento.clienteFinale = rapportino.clienteFinale;
      this.vistaTabellare.push(elemento);
    });

    this.permessiMenoOttoOre.forEach(permesso => {
      let elemento = new StampaTabellaRapportinoDTO();
      elemento.collaboratore = permesso.collaboratore;
      elemento.data = new Date(permesso.presenza.dataPresenza);
      elemento.location = "-";
      elemento.cliente = "-";
      elemento.attivita = "PERMESSO";
      elemento.numeroOre = permesso.numeroOre;
      elemento.clienteFinale = "-";
      this.vistaTabellare.push(elemento);
    })

    this.feriePermessi.forEach(feriePermesso => {
      let elemento = new StampaTabellaRapportinoDTO();
      elemento.collaboratore = feriePermesso.collaboratore;
      elemento.data = new Date(feriePermesso.dataPresenza);
      elemento.location = "-";
      elemento.cliente = "-";
      elemento.attivita = feriePermesso.tipo;
      elemento.numeroOre = feriePermesso.numeroDiOre;
      elemento.clienteFinale = "-";
      this.vistaTabellare.push(elemento);
    })

    this.vistaTabellare.sort((a, b) => {
      return new Date(a.data).getTime() - new Date(b.data).getTime();
    });
    this.raggruppamentoListaPerCliente();
  }
  generateExcel(): void {

    let options = {
      filename: './streamed-workbook.xlsx',
      useStyles: true,
      useSharedStrings: true
    };

    let workbook = new Excel.Workbook(options);

    let worksheet = workbook.addWorksheet('My Sheet', { properties: { tabColor: { argb: 'FFC0000' } } });
    worksheet.columns = [
      { header: 'Collaboratore', key: 'collaboratore', width: 20 },
      { header: 'Data', key: 'data', width: 12 },
      { header: "Location", key: 'location', width: 15 },
      { header: "Cliente", key: "cliente", width: 15 },
      { header: "Ore", key: "numeroOre", width: 7 },
      { header: "Attività", key: "attivita", width: 30 },
      { header: "Cliente Finale", key: "clienteFinale", width: 20 }
    ];

    this.vistaTabellare.forEach(rapportino => {
      let data = new Date();
      data.setTime(rapportino.data.getTime());
      data.setDate(rapportino.data.getDate() + 1);
      worksheet.addRow({
        collaboratore: rapportino.collaboratore.nome + " " + rapportino.collaboratore.cognome,
        data: data,
        location: rapportino.location,
        cliente: rapportino.cliente,
        numeroOre: rapportino.numeroOre,
        attivita: rapportino.attivita,
        clienteFinale: rapportino.clienteFinale
      });
    })


    //Header
    worksheet.getRow(1).height = 22;

    worksheet.getRow(1).alignment = {
      vertical: 'middle',
      horizontal: 'center'
    }

    worksheet.getRow(1).font = {
      name: 'Georgia',
      family: 4,
      size: 16,
      bold: true,
    };

    worksheet.getCell('A1').border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    }
    worksheet.getCell('B1').border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    }
    worksheet.getCell('C1').border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    }
    worksheet.getCell('D1').border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    }
    worksheet.getCell('E1').border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    }
    worksheet.getCell('F1').border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    }
    worksheet.getCell('G1').border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    }

    //Contenuto Tabella

    for (let i = 2; i < this.vistaTabellare.length + 2; i++) {
      worksheet.getRow(i).height = 18;
      worksheet.getCell('A' + i).alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      worksheet.getCell('B' + i).alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      worksheet.getCell('C' + i).alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      worksheet.getCell('D' + i).alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      worksheet.getCell('E' + i).alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      worksheet.getCell('F' + i).alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      worksheet.getCell('G' + i).alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };

      worksheet.getCell('A' + i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
      worksheet.getCell('B' + i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
      worksheet.getCell('C' + i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
      worksheet.getCell('D' + i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
      worksheet.getCell('E' + i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
      worksheet.getCell('F' + i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
      worksheet.getCell('G' + i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
    }


    let fileName = this.rapportinoFinale.collaboratore.nome + "_" + this.rapportinoFinale.collaboratore.cognome + "_" + this.mesi[this.rapportinoFinale.mese - 1].substring(0, 4) + "_" + this.rapportinoFinale.anno + ".xlsx";
    const excelBuffer: any = workbook.xlsx.writeBuffer();
    workbook.xlsx.writeBuffer()
      .then(function (buffer) {
        // done buffering
        const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(data, fileName);
      });
  }


  hiddenColumns(): number[] {
    let ret: number[] = [];
    this.displayedColumns.forEach((element, index) => {
      if (element == "detail" || element == "print") {
        ret.push(index);
      }
    });
    return ret;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  //grafici

  get isMobile(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  am4themes_myTheme(target) {
    if (target instanceof am4charts.PieSeries) {
      let colorSet = new am4core.ColorSet();
      colorSet.step = 4;
      colorSet.baseColor = new Color({
        r: 50,
        g: 165,
        b: 237
      });
      target.colors = colorSet;

      target.labels.template.text = "{key}: {value} ore";
      target.slices.template.tooltipText = "{key}: {value} ore";
      target.legendSettings = new am4charts.LegendSettings();
      target.legendSettings.labelText = "{key}: {value} ore";
      target.legendSettings.valueText = " ";
    } else if (target instanceof am4plugins.Sunburst) {
      let colorSet = new am4core.ColorSet();
      colorSet.step = 4;
      colorSet.baseColor = new Color({
        r: 50,
        g: 165,
        b: 237
      });
      target.colors = colorSet;
    }
  }

  drawChart(event: MatTabChangeEvent) {
    am4core.addLicense("CH299766748");

    if (event.tab.textLabel == "Grafico") {
      //chart rapportini per attività
      // Chart code goes in here
      this.browserOnly(() => {
        am4core.useTheme(am4themes_animated);
        am4core.useTheme(this.am4themes_myTheme);
        let chart = am4core.create("chartCard", am4charts.PieChart);
        chart.paddingRight = 20;
        let title = chart.titles.create();
        title.text = "Grafico per attività";
        title.fontSize = 25;
        title.margin(10, 0, 0, 0);

        var array: PieValuesDTO[] = this.inData.map(r => ({ key: r.attivita.descrizione, value: r.numeroOre }));
        var newArr = [];
        // eliminazione delle attività duplicate:
        array.forEach(function (a) {
          if (!this[a.key]) {
            this[a.key] = { key: a.key, value: 0 };
            newArr.push(this[a.key]);
          }
          this[a.key].value += a.value;
        }, Object.create(null));

        chart.data = newArr;

        // Add and configure Series
        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "key";
        //tick disabilitati
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        //legenda
        chart.legend = new am4charts.Legend();
        chart.legend.useDefaultMarker = true;
        this.isMobile ? chart.legend.position = "bottom" : chart.legend.position = "right";
        chart.legend.margin(0, 0, 20, 0);
        chart.legend.maxHeight = 400;
        chart.legend.scrollable = true;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.strokeWidth = 2;
        marker.strokeOpacity = 1;
        marker.stroke = am4core.color("#ccc");



      });
    }

    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      am4core.useTheme(this.am4themes_myTheme);
      // am4core.useTheme(am4themes_material);

      let chartCliente = am4core.create("chartCardCliente", am4charts.PieChart);
      chartCliente.paddingRight = 20;
      let title = chartCliente.titles.create();
      title.text = "Grafico per cliente";
      title.fontSize = 25;
      title.margin(10, 0, 0, 0);

      var array: PieValuesDTO[] = this.inData.map(r => ({ key: r.cliente.descrizione, value: r.numeroOre }));
      var newArr = [];
      // eliminazione delle attività duplicate:
      array.forEach(function (a) {
        if (!this[a.key]) {
          this[a.key] = { key: a.key, value: 0 };
          newArr.push(this[a.key]);
        }
        this[a.key].value += a.value;
      }, Object.create(null));

      chartCliente.data = newArr;


      // Add and configure Series
      let pieSeriesCliente = chartCliente.series.push(new am4charts.PieSeries());
      pieSeriesCliente.dataFields.value = "value";
      pieSeriesCliente.dataFields.category = "key";
      //tick disabilitati
      pieSeriesCliente.labels.template.disabled = true;
      pieSeriesCliente.ticks.template.disabled = true;

      //legenda
      chartCliente.legend = new am4charts.Legend();
      chartCliente.legend.useDefaultMarker = true;
      this.isMobile ? chartCliente.legend.position = "bottom" : chartCliente.legend.position = "right";
      chartCliente.legend.margin(0, 0, 20, 0);
      chartCliente.legend.maxHeight = 400;
      chartCliente.legend.scrollable = true;
      let markerCliente = chartCliente.legend.markers.template.children.getIndex(0);
      markerCliente.strokeWidth = 2;
      markerCliente.strokeOpacity = 1;
      markerCliente.stroke = am4core.color("#ccc");


    });

    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      am4core.useTheme(this.am4themes_myTheme);

      let chart = am4core.create("chartDiv", am4plugins.Sunburst);

      let title = chart.titles.create();
      title.text = "Grafico attività/cliente";
      title.fontSize = 25;
      title.margin(10, 0, 0, 0);

      var array = this.inData.map((r: RapportinoDTO) => ({ cliente: r.cliente.descrizione, attivita: r.attivita.descrizione, value: r.numeroOre }));
      var newArr = [];
      // eliminazione delle attività duplicate:
      array.forEach(function (a) {
        let previous = this.findBy(newArr, a.cliente);

        if (previous == null) {
          newArr.push({
            key: a.cliente, children: [
              { key: a.cliente + " - " + a.attivita, value: a.value }
            ]
          });
        } else {
          let previousChild = this.findBy(previous.children, a.cliente + " - " + a.attivita);
          if (previousChild == null) {
            previous.children.push(
              { key: a.cliente + " - " + a.attivita, value: a.value }
            );
          } else {
            previousChild.value += a.value;
          }
        }

      }, this);

      chart.data = newArr;

      chart.dataFields.value = "value";
      chart.dataFields.name = "key";
      chart.dataFields.children = "children";

      var level0SeriesTemplate = new am4plugins.SunburstSeries();
      level0SeriesTemplate.hiddenInLegend = false;
      chart.seriesTemplates.setKey("0", level0SeriesTemplate)
      level0SeriesTemplate.labels.template.disabled = true;

      var level1SeriesTemplate = level0SeriesTemplate.clone();
      chart.seriesTemplates.setKey("1", level1SeriesTemplate);
      level1SeriesTemplate.fillOpacity = 0.75;
      level0SeriesTemplate.hiddenInLegend = true;
      level1SeriesTemplate.hiddenInLegend = false;

      chart.legend = new am4charts.Legend();
      chart.legend.useDefaultMarker = true;
      this.isMobile ? chart.legend.position = "bottom" : chart.legend.position = "right";
      chart.legend.margin(0, 0, 20, 0);
      chart.legend.maxWidth = this.isMobile ? 100 : 300;
      chart.legend.maxHeight = this.isMobile ? 100 : 400;
      chart.legend.scrollable = true;

      let markerCliente = chart.legend.markers.template.children.getIndex(0);
      markerCliente.strokeWidth = 2;
      markerCliente.strokeOpacity = 1;
      markerCliente.stroke = am4core.color("#ccc");
    });

  }

  findBy(array: [], paramValue: any) {
    let ret = null;

    array.forEach((a) => {
      if (paramValue === (a as { key: string }).key) {
        ret = a;
      }
    });
    return ret;
  }

  raggruppamentoListaPerCliente() {
    //settimana
      this.vistaTabellare.forEach((elemento) => {
          if(!this.isString(elemento)) {
              let data = new Date(elemento.data);
              let numeroSettimana = getWeekOfMonth(data);
              if(!this.tabellaPerSettimana[numeroSettimana]) {
                  this.tabellaPerSettimana[numeroSettimana] = [];
              }
              this.tabellaPerSettimana[numeroSettimana].push(elemento);
          }
      });
      this.tabellaPerSettimana = this.tabellaPerSettimana.filter(array => array.length > 0);
      this.tabellaRaggruppata = new Array(this.tabellaPerSettimana.length);
      for(let i = 0; i < this.tabellaPerSettimana.length; i++) {
          from(this.tabellaPerSettimana[i]).pipe(
              groupBy(riga => (riga as any).clienteFinale, r => r),
              mergeMap(group$ => group$.pipe(
                  reduce((acc, cur) => [...acc, cur], [`${group$.key}`]))))
              .subscribe(r => {
                if(!this.tabellaRaggruppata[i]) {
                  this.tabellaRaggruppata[i] = [];
                  this.tabellaRaggruppata[i].push({
                    settimana: "Settimana dal " + (r[1] as StampaTabellaRapportinoDTO).data.getDate() + "/" + ((r[1] as StampaTabellaRapportinoDTO).data.getMonth() + 1)
                  });
                }
                this.tabellaRaggruppata[i].push(r);
              });
      }
      this.tabellaRaggruppata = this.tabellaRaggruppata.reduce((acc, item) => acc.concat(item), []);
      for(let i = 0; i < this.tabellaRaggruppata.length; i++) {
        debugger
        if(this.isString(this.tabellaRaggruppata[i][0])) {
          let cliente =this.tabellaRaggruppata[i][0];
              this.tabellaRaggruppata[i][0] = {
                totale: this.calcoloOrePerCliente(this.tabellaRaggruppata[i]),
                clienteTotale: cliente
              }
        }
      }
      this.tabellaRaggruppata = this.tabellaRaggruppata.reduce((acc, item) => acc.concat(item), []);
  }

  calcoloOrePerCliente(cliente) {
    let numeroOre = 0;
      for(let i = 0; i < cliente.length; i++) {
          if(!this.isString(cliente[i])) {
              numeroOre += cliente[i].numeroOre;
          }
      }
    return numeroOre;
  }
  isString(elemento) {
    return typeof elemento === 'string';
  }

  isNumber(elemento) {
      return typeof elemento === 'number';
  }

  rowClass(row: any): string {
    if(row.settimana ) {
      return 'settimana';
    } else if(row.totale) {
      return 'totale';
    } else {
      return '';
  }
}
}

export interface selectedValue {
  value: string;
  viewValue: string;
}

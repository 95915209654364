
import { Component, OnDestroy, OnInit } from '@angular/core';
import { GenericListComponent } from 'src/app/shared/GenericListCoumponent';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { ComponentCacheService } from 'src/app/services/component-cache.service';
import { NavigatorService } from 'src/app/services/navigator.service';
import { TimestampFormatPipe } from 'src/app/commons/timestampFormatPipe';
import { CollaboratoreDTO } from 'src/app/shared/dto/domain/collaboratore';
import { ResponseQueryByCriteria } from 'src/app/shared/dto/responseQueryByCriteria';
import { DispositivoDTO } from 'src/app/shared/dto/dispositivo/dispositivo';
import { DispositivoService } from '../services/dispositivo/dispositivo.service';
import { CollaboratoreService } from '../services/domain/collaboratore.service';

@Component({
  selector: 'app-dispositivo',
  templateUrl: './dispositivo.component.html',
  styleUrls: ['./dispositivo.component.scss']
})
export class DispositivoComponent extends GenericListComponent implements OnInit, OnDestroy {

  collaboratori: CollaboratoreDTO[];
  dispositivo: DispositivoDTO;

  defaultSortField = "codice";
  defaultSortDirection = 'asc';
  
  constructor(
    private dispositivoService : DispositivoService,
    private  collaboratoreService: CollaboratoreService,
    componentCacheService: ComponentCacheService,
    navigatorService: NavigatorService,
    dateAdapter: DateAdapter<Date>,
    router: Router,
    snackBar: MatSnackBar,
    timestampFormatPipe: TimestampFormatPipe
  ) {
    super(navigatorService,
      componentCacheService,
      dateAdapter,
      router,
      snackBar,
      timestampFormatPipe);
      this.displayedColumns = ['codice', 'collaboratore', 'descrizione', 'detail'];

      this.parameters = {
        dataSource: [],
        showList: false,
        collaboratore: null,
        collaboratoreChanged: false,
        collaboratori: [],
        selectedLocations: [],
        selectedLocationsIds: [],
        initDataDa: true,
        dataDa: null,
        dataA: null,
        totaleOre: null,
        giorni: null,
        sortField: 'codice',
        sortDirection: 'DESC',
        pageNumber: 0,
        pageSize: 50,
        length: 0
      }
   }

  ngOnInit(){
    super.ngOnInit();
    if(!this.parameters.collaboratoreChanged){
      this.navigatorService.collaboratore.subscribe(
        (res: CollaboratoreDTO) => {
          this.parameters.collaboratore = res;
        }
      );
    }
    this.collaboratoreService.collaboratoreList(
      0,
      9999,
      'ASC',
      'nome',
      '').subscribe(
        (res: ResponseQueryByCriteria<CollaboratoreDTO>) => {
          console.log("response QUESTA: ", res);
          this.collaboratori = res.content;
        }
      );
  }

  list() {
    if (this.parameters.selectedLocations.length != 0) {
      let i = 0;
      this.parameters.selectedLocationsIds = [];
      this.parameters.selectedLocations.forEach(location => {
        this.parameters.selectedLocations[i] = location;
        this.parameters.selectedLocationsIds[i] = location.id;
        i++;
      });
    }
    if (this.parameters.collaboratore != null) {
      this.dispositivoService.dispositivoList(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
        (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField,
        this.parameters.collaboratore.id
      ).subscribe(
        (res: ResponseQueryByCriteria<DispositivoDTO>) => {
          console.log("response : ", res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        });
    } else {
      this.dispositivoService.dispositivoListAll(
        this.parameters.pageNumber,
        this.parameters.pageSize,
        (this.parameters.sortField == null && this.parameters.sortDirection == null) ? this.defaultSortDirection : this.parameters.sortDirection,
        (this.parameters.sortField == null) ? this.defaultSortField : this.parameters.sortField
      ).subscribe(
        (res: ResponseQueryByCriteria<DispositivoDTO>) => {
          console.log("response : ", res);
          this.parameters.dataSource = res.content;
          this.parameters.pageNumber = res.pageNumber;
          this.parameters.length = res.totalElements;
          this.parameters.showList = true;
        })
    }
    
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
